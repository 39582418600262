import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Click on the button at the middle right edge of the screen.`}</p>
    <br />
    <p>{`In the menu that floats in from the right, you can select between different
maps from topographical maps to satellite pictures. The available map layers
change with the location and zoom level of the map.`}</p>
    <br />
    <Image src="features/mapselector.png" className="w-1/2 my-12 mx-auto" mdxType="Image" />
    <h4>{`Split map`}</h4>
    <p>{`Showing a satellite image next to a detailed topographical map makes it
very easy to see the surface type of roads and trails. It can me a very
helpful tool to find new trails, especially above the tree line.
Or use it to compare the details of two different maps.`}</p>
    <Image src="features/splitmap.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <h4>{`Grayscale`}</h4>
    <p>{`Turning on the grayscale option will transform the colors of all maps to
gray tones, helping you separate the overlaid content from the base map.`}</p>
    <Image src="features/grayscale.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <p>{`If you have selected an option in the map menu, the layer icon turns blue to
indicate active changes.`}</p>
    <br />
    <p>{`When you close and reopen the app, these options are saved and restored.`}</p>
    <br />
    <br />
    <Link className="float-left" to="/features/activitybuttons" mdxType="Link">
  ← Action buttons
    </Link>
    <Link className="float-right" to="/features/overlays" mdxType="Link">
  Overlays →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      